

































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    dcmLists: Array,
    dcmFields: Array
  }
})

export default class ATSComponentsImgLabelTable extends Vue {
}
