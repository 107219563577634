












































import { Component, Vue } from 'vue-property-decorator'
import globalMixins from '@/mixins/global.ts'
import { Getter, Action } from 'vuex-class'
import utils from '@/utils/util.ts'
import dcmTable from '@/components/imgLabel/grid.vue'

@Component({
  components: {
    dcmTable
  },
  mixins: [globalMixins]
})
export default class imgLabelIndex extends Vue<globalMixins> {
  @Getter('dcmTotal') dcmTotal: Number
  @Getter('dcmLists') dcmLists: Object[]
  @Getter('dcmFields') dcmFields: any[]
  @Action('getDcmLists') getDcmLists: Function
  
  project: any = {}
  status: string = 'finished'
  query: any = {
    keyword: ''
  }
  pagination: any = {
    page: 1,
    size: 10
  }
  loading: boolean = false
  created () {
    this.project = JSON.parse(localStorage.getItem("projects"))
    this.query['projectId'] = this.project.id
    this.query['label_status'] = this.status
    this.doSearch()
  }
  handleTabClick () {
    this.query['label_status'] = this.status === 'none' ? '' : this.status
    this.doSearch()
  }
  doKeywordSearch () {
    this.pagination.page = 1
    this.doSearch()
  }
  onPagerSizeChange (val: number) {
    this.pagination.size = val
    this.doSearch()
  }
  onPagerCurrentChange (val: number) {
    this.pagination.page = val
    this.doSearch()
  }
  doSearch () {
    this.loading = true
    let pagination = utils.pager(this.pagination.page, this.pagination.size)
    let params = Object.assign({}, this.query, {limit: pagination.limit}, {offset: pagination.offset}) 
    if (this.query.keyword === '') {
      delete params.keyword
    }
    this.getDcmLists({ params }).then(() => {
      this.loading = false
    })
  }
}
